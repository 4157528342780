import Vue from "vue";
import { GridPlugin, Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import titleMixin from "@/mixins/title";
import gridMixin from "@/mixins/syncfusion/grid";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [gridMixin, titleMixin],
    data() {
        return {
            title: this.$t("customers.customers"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "customers/grid-data",
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-edit",
                removeUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            sortOptions: {
                columns: [
                    { field: "Surname", direction: "Ascending" },
                    { field: "Name", direction: "Ascending" }
                ]
            },
            searchOptions: {
                fields: ["Name", "Surname", "FiscalCode", "Email"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Appointments",
                    title: "Lista appuntamenti",
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-appointments e-icons"
                    }
                },
                {
                    type: "Attachments",
                    title: "Allegati",
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-folder-open e-icons"
                    }
                },
                {
                    type: "HealthPath",
                    title: "Percorsi",
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-resizer e-icons"
                    }
                },
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ]
        };
    },
    provide: {
        grid: [Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn]
    },
    methods: {
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push("/customers/create");
                return;
            }
        },
        commandClick(args) {
            if (args.commandColumn.type == "Appointments") {
                this.$router.push("/customers/" + args.rowData.Id + "/appointments");
                return;
            }
            if (args.commandColumn.type == "Attachments") {
                this.$router.push("/customers/" + args.rowData.Id + "/attachments");
                return;
            }
            if (args.commandColumn.type == "HealthPath") {
                this.$router.push("/customers/" + args.rowData.Id + "/healthPath");
                return;
            }
            if (args.commandColumn.type === "Custom_Edit") {
                this.$router.push("/customers/" + args.rowData.Id + "/edit");
                return;
            }
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("customers.delete.toastSuccessTitle"), this.$t("customers.delete.toastSuccessContent", {
                    name: `${args.data[0].Name} ${args.data[0].Surname}`
                }));
            }
        }
    }
});
